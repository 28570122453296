<script>
import { mapState } from 'vuex';

import EqualizationPaymentVue from './Table/EqualizationPayment.vue';
import HeaderRow from './Table/HeaderRow.vue';
import Section from './Table/Section.vue';
import SectionHeader from './Table/SectionHeader.vue';
import SummararyRow from './Table/SummaryRow.vue';

import { getPartnerName } from '@/lib/agreement/filters';
import property from '@/mixins/property';
import equalizationCalculations from '@/mixins/equalizationCalculations';
import FamilyResidence from './Agreements/FamilyResidence.vue';
import AdditionalRealProperties from './Agreements/AdditionalRealProperties.vue';
import Vehicles from './Agreements/Vehicles.vue';
import FinancialAccounts from './Agreements/FinancialAccounts.vue';
import Businesses from './Agreements/Businesses.vue';
import Corporations from './Agreements/Corporations.vue';
import Pensions from './Agreements/Pensions.vue';
import Liabilities from './Agreements/Liabilities.vue';
import RESP from './Agreements/RESP';
import RRSP from './Agreements/RRSP';
import OtherProperty from './Agreements/OtherProperty.vue';
// import { analytics } from '../firebase';
import {trialActiveForClients} from '@/mixins/roleLevelPermissions';


export default {
	name: 'PropertySchedule',
	components: {
		'header-row': HeaderRow,
		'table-section': Section,
		'section-header': SectionHeader,
		'summary-row': SummararyRow,
		'equalization-payment': EqualizationPaymentVue,
		'family-residence':FamilyResidence,
		'additional-property': AdditionalRealProperties,
		'vehicles': Vehicles,
		'financial-accounts': FinancialAccounts,
		'businesses': Businesses,
		'corporations':Corporations,
		'other-property':OtherProperty,
		'pensions': Pensions,
		'liabilities': Liabilities,
		'RESP': RESP,
		'RRSP': RRSP
	},
	props: [
		'agreementData'
	],
	mixins: [property, equalizationCalculations],
	data(){
		return{
			optionsForEdit:  undefined,
			optionsForAdd: undefined,
			// assetViewValue: localStorage.getItem('isAssetView') ? JSON.parse(localStorage.getItem('isAssetView')) : true,
			isAssetView: true,
			isPreview: false,
		}
	},
	computed: {
		...mapState(['agreement']),
		columns() {
			const parter1 = getPartnerName('0', this.agreement);
			const parter2 = getPartnerName('1', this.agreement);
			return [
				{
					label: 'Description',
					field: 'description',
					width: '20vw',
					variant: 'light',
					isAssetView: false,	
				},
				{
					label: 'Ownership',
					field: 'ownership',
					width: '6vw',
					variant: 'light',
					isAssetView: false,					
				},
				{
					label: 'Asset Particulars',
					field: 'comments',
					width: '20vw',
					variant: 'light',
					class: 'text-center',
					isAssetView: false,					
				},
				{
					label: 'Value',
					field: 'value',
					variant: 'light',
					class: 'text-center',
					width: '6vw',
					isAssetView: false,					
				},
				{
					label: `${parter1}'s Excluded property value`,
					field: 'spouceOneExclusions',
					class: 'text-center',
					id: 'property-schedule-partner1-name',
					name: parter1,
					width: '6vw',
					variant: 'light',
					isAssetView: this.isAssetView,					
				},
				{
					label: `${parter2}'s Excluded property value`,
					field: 'spouceTwoExclusions',
					class: 'text-center',
					id: 'property-schedule-partner2-name',
					name: parter2,
					width: '6vw',

					variant: 'light',
					isAssetView: this.isAssetView,					
				},
				{
					label: 'Shared',
					field: 'shared',
					variant: 'light',
					class: 'text-center',
					width: '6vw',
					isAssetView: this.isAssetView,					
				},
				{
					label: `${parter1}'s Retained Property`,
					field: 'spouseOne',
					class: 'text-center',
					width: '6vw',
					variant: 'light',
					isAssetView: this.isAssetView,					
				},
				{
					label: `${parter2}'s Retained Property`,
					field: 'spouseTwo',
					class: 'text-center',
					width: '6vw',
					variant: 'light',
					isAssetView: this.isAssetView,					
				},
				{
					label: ``,
					field: '',
					class: 'text-center',
					width: '3vw',
					variant: 'light',
					isAssetView: false,	
				},
			];
		},
		isMobile(){
			return window.innerWidth < 768
		},
		agreementFilledDetails() {
			return this.$store.getters.agreementDetails;
			// return this.agreementData;
		},
		editorEnabled() {
			return this.agreementData.meta && this.agreementData.meta.editing;
		},
		isIpad(){
			return (window.innerWidth > 670) && (window.innerWidth < 821)
		},
		rrspTransfer(){
			if(this.agreementFilledDetails && this.agreementFilledDetails.registeredRetirementSavings && this.agreementFilledDetails.registeredRetirementSavings.transfer){
				return this.agreementFilledDetails.registeredRetirementSavings.transfer
			}else{
				return ''
			}
		},
		rrspTransferAmount(){
			if(this.agreementFilledDetails && this.agreementFilledDetails.registeredRetirementSavings && this.agreementFilledDetails.registeredRetirementSavings.transferAmount){
				return this.agreementFilledDetails.registeredRetirementSavings.transferAmount
			}else{
				return 0
			}
		},
		includeEqPayment(){
			if(this.agreementFilledDetails && this.agreementFilledDetails.equalizationPayment && this.agreementFilledDetails.equalizationPayment.includeEqPayment){
				return this.agreementFilledDetails.equalizationPayment.includeEqPayment
			}else{
				return false
			}
		},
		useDiviiAmount(){
			if(this.agreementFilledDetails && this.agreementFilledDetails.equalizationPayment && this.agreementFilledDetails.equalizationPayment.useDiviiAmount){
				return this.agreementFilledDetails.equalizationPayment.useDiviiAmount
			}else{
				return false
			}
		},
		eqPaymentSpecificAmount(){
				if(this.agreementFilledDetails && this.agreementFilledDetails.equalizationPayment && this.agreementFilledDetails.equalizationPayment.amount){
					return this.agreementFilledDetails.equalizationPayment.amount
				}else{
					return 0
				}
		}

	},
	mounted() {
		// analytics.logEvent('screen_view', {
		// 	firebase_screen: 'property_schedule',
		// 	firebase_screen_class: 'PropertySchedule',
		// });
		// this.$root.$on('bv::modal::hidden', () => {
		// 	if(this.optionsForEdit && this.optionsForEdit.sectionLabelId && this.optionsForEdit.sectionLabelId == 'corporation')
		// 		location.reload();
		// })
		console.log('-----------------------',localStorage.getItem('isAssetView'));
		if ((localStorage.getItem('isAssetView') && JSON.parse(localStorage.getItem('isAssetView'))) || this.trialActiveForClients()) {
			this.isAssetView = true
		} else {
			this.isAssetView = false
		}
		if(window.location.href.indexOf("preview") > -1) {
			this.isPreview=true;
		}
		
	},
	methods:{
		openEditModal(optionsForEdit){
			setTimeout(() => {
				let closeButton=  document.getElementsByClassName('modal-header')[0].getElementsByClassName('close')[0]
				closeButton.id = 'close-edit-modal';
			}, 1000);
			this.optionsForEdit = optionsForEdit;
			this.openModal(optionsForEdit.modalId);
		},
		openAddModal(optionsForAdd){
			setTimeout(() => {
				let closeButton=  document.getElementsByClassName('modal-header')[0].getElementsByClassName('close')[0]
				closeButton.id = 'close-modal';
			}, 1000);
			this.optionsForAdd = optionsForAdd;
			this.openModal(optionsForAdd.modalId);
		},
		openModal(modalId){
			setTimeout(() => {
				this.$bvModal.show(modalId);
			}, 700);
		},
		assetView(){
			this.isAssetView = true
			localStorage.setItem('isAssetView', true)
			return this.isAssetView
		},
		distributionView(){
			this.isAssetView = false
			localStorage.setItem('isAssetView', false)
			return this.isAssetView
		},
		trialActiveForClients
		}
};
</script>

<style>
.table-primary {
	background-color: #ffff ;
}
.table-success {
	background-color: #9CB6BD;
}
.table-dark {
	background-color: #243B42;
	color: #fff;
}
.table-light {
	background-color: #3F6772;
	color: #fff;
}
.table-secondary{
	background-color: #678B95;
	color: #fff;
}
.table-pastel-blue{
	background-color: #9CB6BD !important;
}
.section-header {
	border-bottom: 1px solid #dddddd !important;
}
.table-head {
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
	overflow-y: auto !important;
}
.table-secondary{
	background-color: #678B95;
	color: #fff;
}
.scroll-x{
	overflow-x: auto !important;
}
</style>

<template>
	<div>
		<div v-if="!clientUserAgreementId && !isPreview && !trialActiveForClients()">
			<b-tabs content-class="mt-3" justified>
				<b-tab title="Assets View" @click="assetView()" :active="isAssetView"></b-tab>
				<b-tab title="Distribution View" @click="distributionView()" :active="!isAssetView"></b-tab>
			</b-tabs>
		</div>
		<!-- Test{{ isAssetView }} -->

	<b-table-simple
		small
		caption-top
		responsive
		sticky-header
		class="table-head mt-2 custom-scroll" v-bind:class="{'scroll-small': isMobile, 'scroll-x': isIpad}"
		style="max-height: calc(100vh - 185px)"
	>
		<b-thead>
			<header-row :columns="columns" />
		</b-thead>
		<!-- <b-tbody> -->
			<section-header label="Assets" :isAssetView="isAssetView"/>
			<table-section
				v-if="(property.length) && (agreementFilledDetails.agreement.propertyRecitals.includes('Family Residence') || 
											agreementFilledDetails.agreement.propertyRecitals.includes('Additional Real Property'))"
				:items="property.filter(property => property.enabled === true)"
				label="Real Property"
				labelId="real-property"
				:agreementData="agreementData"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="(vehicles.length) && (agreementFilledDetails.agreement.propertyRecitals.includes('Vehicles'))"
				:items="vehicles"
				label="Vehicles"
				labelId="vehicles"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="(accounts.length) && (agreementFilledDetails.agreement.propertyRecitals.includes('Financial Accounts'))"
				:items="accounts"
				label="Financial Accounts"
				labelId="financial-accounts"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="onlyRRSPWithDiscounts.length && (agreementFilledDetails.agreement.propertyRecitals.includes('RRSPs'))"
				:items="onlyRRSPWithDiscounts"
				label=""
				labelId="RRSP"
				@openEditModal="openEditModal"
				:isAssetView="isAssetView"
			/>

			<!-- <table-section
				v-if="(businesses.length) && (agreementFilledDetails.agreement.propertyRecitals.includes('Business') ||
										(agreementFilledDetails.agreement.propertyRecitals.includes('Corporation')))"
				:items="businesses.filter(business => business.enabled === true)"
				label="Corporate / Business Interests"
				labelId="corporate-interests"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
			/> -->
			<table-section
				v-if="onlyBusiness.length && (agreementFilledDetails.agreement.propertyRecitals.includes('Business'))"
				:items="onlyBusiness"
				label="Business Interests"
				labelId="business"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="onlyCorporation.length && (agreementFilledDetails.agreement.propertyRecitals.includes('Corporation'))"
				:items="onlyCorporation"
				label="Corporate Interests"
				labelId="corporation"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="(otherProperties.length) && (agreementFilledDetails.agreement.propertyRecitals.includes('Other Property'))"
				:items="otherProperties"
				label="Other Property"
				labelId="other-property"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="(agreementFilledDetails.agreement.propertyRecitals.includes('Pensions'))"
				:items="onlyAfterTaxPensions"
				label="Pensions"
				labelId="pensions"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<summary-row label="Total Assets:" :summary="assetsSummary" :isAssetView="isAssetView" :variant="'pastel-blue'"/>

			<section-header v-if="liabilities.length" label="Liabilities" :isAssetView="isAssetView"/>
			<table-section v-if="liabilities.length" :items="liabilities" labelId="liabilities" label="Liabilities" @openEditModal="openEditModal" @openAddModal="openAddModal" :isAssetView="isAssetView"/>

			<summary-row
				v-if="liabilities.length"
				label="Total Liabilities:"
				:summary="liabilitySummary"
				:isAssetView="isAssetView"
				:variant="'pastel-blue'"
			/>

			<!-- net estate -->
			<summary-row
				label="NET ESTATE (excluding Registered Assets)"
				:summary="netSummary"
				variant="secondary"
				:isAssetView="isAssetView"
			/>
			<!-- <template v-if="agreementData"> -->
			<!-- <equalization-payment
				label="Equalization Payment to balance shared property"
				:summary="propertyEqualization"
				:eqPaymentIncludes= "agreementData.equalizationPayment.includeEqPayment"
				:eqUseFigure="agreementData.equalizationPayment.useDiviiAmount"
				:eqPaymentAmount="agreementData.equalizationPayment.amount"
				property-type="assets"
			/> -->
			<equalization-payment
				label="Equalization Payment to balance shared property"
				:summary="propertyEqualization"
				:eqPaymentIncludes= "includeEqPayment"
				:eqUseFigure="useDiviiAmount"
				:eqPaymentAmount="eqPaymentSpecificAmount"
				property-type="assets"
				:isAssetView="isAssetView"
			/>
			<!-- </template> -->

			<section-header
				v-if="registeredAccounts.length && (agreementFilledDetails.agreement.propertyRecitals.includes('RRSPs') ||
													(agreementFilledDetails.agreement.propertyRecitals.includes('RESPs')||
													(agreementFilledDetails.agreement.propertyRecitals.includes('Pensions') &&
													onlyBeforeTaxPensions.length)))"
				label="Registered Assets"
				:isAssetView="isAssetView"
			/>
			<!-- <table-section :items="registeredAccounts.filter(registeredAccount => registeredAccount.enabled === true)" labelId="registeredAccounts" label="Registered Assets" @openEditModal="openEditModal" @openAddModal="openAddModal"/> -->
			<table-section
				v-if="onlyRESP.length && (agreementFilledDetails.agreement.propertyRecitals.includes('RESPs'))"
				:items="onlyRESP"
				label="RESP"
				labelId="RESP"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>

			<table-section
				v-if="agreementFilledDetails.agreement.propertyRecitals.includes('RRSPs')"
				:items="onlyRRSPWithoutDiscounts"
				label="RRSP"
				labelId="RRSP"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>
			<table-section
				v-if="(onlyBeforeTaxPensions.length) && (agreementFilledDetails.agreement.propertyRecitals.includes('Pensions'))"
				:items="onlyBeforeTaxPensions"
				label= "Pensions"
				labelId="pensions"
				@openEditModal="openEditModal"
				@openAddModal="openAddModal"
				:isAssetView="isAssetView"
			/>
			<summary-row
				v-if="registeredAccounts.length && (agreementFilledDetails.agreement.propertyRecitals.includes('RRSPs') ||
													(agreementFilledDetails.agreement.propertyRecitals.includes('RESPs'))||
													(agreementFilledDetails.agreement.propertyRecitals.includes('Pensions') &&
													onlyBeforeTaxPensions.length))"
				label="Total Registered Assets:"
				:summary="registeredAssetSummary"
				:isAssetView="isAssetView"
				:variant="'pastel-blue'"
			/>

			<equalization-payment
				v-if="registeredAccounts.length && (agreementFilledDetails.agreement.propertyRecitals.includes('RRSPs'))"
				label="Equalization Payment to balance shared registered property"
				:summary="registeredPropertyEqualization"
				:rrspTransfer="rrspTransfer"
				:rrspTransferAmount="rrspTransferAmount"
				property-type="registered-assets"
				:isAssetView="isAssetView"
			/>

			<b-modal id="edit-modal" size="xl" ref="edit-modal" v-if="optionsForEdit" hide-footer title="Quick Editor">
				<b-alert variant="warning" show>
					<h5 class="alert-heading">Heads Up!</h5>
					<div v-if="editorEnabled">
						You've already disconnected from the Builder. Any changes made here will not be reflected in your working document unless you select Update in the Editor tab. Updating in the Editor tab will revert your working document to the Builder version and overwrite any manual copy edits you've made in the Editor. 
						<b>If you choose not to revert, any change to values in this table will need to be manually updated in your working document.</b>
					</div>
					<div v-else>
						Any changes you make here are saved automatically and will be reflected in your Agreement Builder and Preview.
					</div>
				</b-alert>
				<template v-if="optionsForEdit.sectionLabelId =='family-residence'">
					<family-residence :agreementData="agreementFilledDetails"></family-residence>
				</template>
				<template v-if="optionsForEdit.sectionLabelId =='additional-property'">
					<additional-property :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></additional-property>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='vehicle'">
					<vehicles :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></vehicles>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='accounts'">
					<financial-accounts :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></financial-accounts>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='business'">
					<businesses :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></businesses>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='corporation'">
					<corporations :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></corporations>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='other-property'">
					<other-property :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></other-property>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='pension'">
					<pensions :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></pensions>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='liabilities'">
					<liabilities :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></liabilities>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='RESP'">
					<RESP :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></RESP>
				</template>
				<template v-else-if="optionsForEdit.sectionLabelId =='RRSP'">
					<RRSP :agreementData="agreementFilledDetails" :itemIndexToShow="optionsForEdit.index" :hideForEdit=true></RRSP>
				</template>
			</b-modal>

			
			<b-modal id="add-modal" ref="add-modal" size="xl" v-if="optionsForAdd" hide-footer title="Quick Editor">
				<b-alert variant="warning" show>
					<h5 class="alert-heading">Heads Up!</h5>
					<div v-if="editorEnabled">
						You've already disconnected from the Builder. Any changes made here will not be reflected in your working document unless you select Update in the Editor tab. Updating in the Editor tab will revert your working document to the Builder version and overwrite any manual copy edits you've made in the Editor. 
						<b>If you choose not to revert, any change to values in this table will need to be manually updated in your working document.</b>
					</div>
					<div v-else>
						Any changes you make here are saved automatically and will be reflected in your Agreement Builder and Preview.
					</div>
				</b-alert>
				<!-- <template v-if="optionsForAdd.sectionLabelId =='family-residence'">
					<family-residence :agreementData="agreementFilledDetails"></family-residence>
				</template> -->
				
				<template v-if="optionsForAdd.sectionLabelId =='real-property'">
					<additional-property :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></additional-property>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='vehicles'">
					<vehicles :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></vehicles>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='financial-accounts'">
					<financial-accounts :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></financial-accounts>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='business'">
					<businesses :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></businesses>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='corporation'">
					<corporations :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></corporations>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='other-property'">
					<other-property :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></other-property>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='pensions'">
					<pensions :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></pensions>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='liabilities'">
					<liabilities :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></liabilities>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='RESP'">
					<RESP :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></RESP>
				</template>
				<template v-else-if="optionsForAdd.sectionLabelId =='RRSP'">
					<RRSP :agreementData="agreementFilledDetails" :forceAddNewItem=true :hideForEdit=true></RRSP>
				</template>
			</b-modal>
		<!-- </b-tbody> -->
	</b-table-simple></div>
</template>
